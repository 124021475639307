const theme = {
  font: {
    primary: `'bilo', serif`,
    secondary: `'bilo', serif`,
  },
  font_size: {
    small: 'font-size: 18px; line-height: 26px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 48px; line-height: 56px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#343434',
      light: '#282828',
      regular: '#1b1b1b',
    },
    primary: '#1b1b2a',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '1024px',
    lg: '1320px',
  },
};

export default theme;
