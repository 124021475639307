import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import SEO from '@common/SEO';

import theme from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';

const Layout = ({ children, title, description }) => (
  <ThemeProvider theme={theme}>
    <>
      <SEO title={title} description={description}/>
      <GlobalStyles />
      {children}
      <MessengerCustomerChat
        pageId="109644770456529"
        loggedInGreeting="Chào bạn, Drobebox có thể giúp gì cho bạn ?"
        loggedOutGreeting="Chào bạn, Drobebox có thể giúp gì cho bạn ?"
        xfbml
        version="6.0"
      />
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
