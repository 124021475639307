import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description: 'Một tủ đồ vô tận, tự giặt giũ, tự mua sắm, và luôn sẵn sàng cùng bạn trong mọi sự thay đổi.',
  title: 'Drobebox | Fashion reInvented',
  url: 'https://drobebox.com',
  author: 'Drobebox',
  keywords: [
  'Drobebox',
  'drobebox là gì',
  'tải drobebox',
  'quần áo thuê ở Drobebox có sạch không',
  'quần áo ở Drobebox có sạch không',
  'thời trang chia sẻ',
  'thời trang bền vững',
  'cloud closet',
  'sharing economy',
  'kinh tế chia sẻ',
  'tủ đồ trên mây',
  'không có gì để mặc',
  'Fashion as a Service',
  'thuê đồ',
  'thuê quần áo',
  'ăn mặc thông minh',
  'các tốt nhất để chia sẻ quần áo',
  'thuê đồ nữ hàng tháng',
  'ứng dụng chia sẻ quần áo',
  'ăn mặc tiết kiệm',
  'thuê quần áo ở đâu'
],
  facebook: {
    id: 'drobebox',
    img: '',
  },
};

const SEO = ({title, description}) => {
  return (
    <Helmet>
      <meta property="fb:app_id" content={SEO_DATA.facebook.id} />
      <meta property="og:title" content={title||SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description||SEO_DATA.description} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{title || SEO_DATA.title}</title>
      <html lang="vi" />
    </Helmet>
  );
};

export default SEO;
